import React from 'react'
import styled from 'styled-components'

const Section = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

`
const Wrapper = styled.div`
    width: 50vw;

    @media (max-width: 768px) {
        width: 80vw;
    }
`

const Text = styled.div`
    width: inherit;
    position: absolute;
    top: 100px;
    padding-bottom: 50px;
    letter-spacing: 1.1px;
    overflow-wrap: break-word;

    h1 {
        padding: 1.5rem 0;
    }

    p {
        padding: 1rem 0;
    }


`

const Impressum = ({impressumData}) => {
    return (
        <Section>
            <Wrapper>
                <Text>
                <h1>Impressum</h1>
                {impressumData.map(line => {
                    return(
                        <p>{line}</p>
                    )
                })}
                <p>
                Haftungshinweis: <br/>
                <br/>
                Für externe, verlinkte Inhalte wird keine Haftung übernommen. <br/><br/>
                ©2021 Wirtschaftsingenieur Michael Frank <br/> <br/>
                Ingenieurbüro Michael Frank
                <br/> <br/>
                {impressumData[impressumData.length -1]}
                </p>
                </Text>
            </Wrapper>
        </Section>
    )
}

export default Impressum
