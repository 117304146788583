import React, { useEffect } from 'react'
import styled, {css} from 'styled-components';
import { Button } from './Button';
import {IoMdArrowBack, IoMdArrowForward } from 'react-icons/io';
import { useState } from 'react';
import { useRef } from 'react';

const HeroSection = styled.section`
    height: 100vh;
    max-height: 1100px;
    position: relative;
    overflow: hidden;
`;

const HeroWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
`;


const HeroSlide = styled.div`
    z-index: 1;
    width: 100%;
    height: 100%;
`;
const HeroSlider = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const HeroImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
`;

const HeroContent = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    max-width: 1600px;
    width: calc(100% - 100px);
    color: #fff;

    h1 {
        font-size: clamp(0.6rem, 8vw, 1.7rem);
        font-weight: 500;
        text-align: left;
        margin-bottom: 0.8rem;
    }

    p {
        margin-bottom: 1.2rem;
    }
`;
const Arrow = styled(IoMdArrowForward)`
    margin-left: 3px;
    transform: scale(1.08);
`;

const SliderButtons = styled.div`
    position: absolute;
    bottom: 70px;
    right: 50px;
    display: flex;
    z-index: 10;

`;

const arrowButtons = css`
    width: 50px;
    height: 50px;
    color: #fff;
    cursor: pointer;
    background: #303030;
    border-radius: 50px;
    padding: 10px;
    margin-right: 1rem;
    user-select: none;
    transition: 0.3s;

    &:hover {
        transform: scale(1.05);
        filter: invert(1);
    }
`;

const PrevArrow = styled(IoMdArrowBack)`
    ${arrowButtons}
`

const NextArrow = styled(IoMdArrowForward)`
    ${arrowButtons}
`

const Hero = ({ slides })  => {
    const [current, setCurrent] = useState(0)
    const length = slides.length
    const timeout = useRef(null)

    useEffect(() => {
        const nextSlide = () => {
            setCurrent(current => (current === length -1 ? 0 : current +1))
        }

        timeout.current = setTimeout(nextSlide, 7*1000)
        
        return function () {
            if(timeout.current) {
                clearTimeout(timeout.current)
            }
        };
    }, [current, length])

    const nextSlide = () => {
        setCurrent(current === length -1 ? 0 : current + 1);
        if(timeout.current) {
            clearTimeout(timeout.current)
        }

        //console.log(current)
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length -1 : current - 1);
        if(timeout.current) {
            clearTimeout(timeout.current)
        }
        //console.log(current)
    }

    if(!Array.isArray(slides) || slides.length <= 0) {
        return null
    }


    return (
        <HeroSection>
            <HeroWrapper>
                {slides.map((slide, index) => {
                    return (
                        <HeroSlide key={index}>
                            {index === current && (
                                <HeroSlider>
                                <HeroImage src={slide.image} alt={slide.alt}/>
                                <HeroContent>
                                    <h1>{slide.title}</h1>
                                    <p>{slide.desc}</p>
                                    <Button to={slide.path} primary='true' css={`max-width: 160px;`}>
                                        {slide.label}
                                        <Arrow />
                                    </Button>
                                </HeroContent>
                            </HeroSlider>
                            )}
                        </HeroSlide>
                    )
                })}
                <SliderButtons>
                    <PrevArrow onClick={prevSlide}/>
                    <NextArrow onClick={nextSlide} />
                </SliderButtons>
            </HeroWrapper>
        </HeroSection>
    )
}

export default Hero;
