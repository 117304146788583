import React from 'react'
import { IoMdArrowBack } from 'react-icons/io';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from './Button';


const Section = styled.section`
    min-height: 100vh;
    width: 100%;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    top: 60px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.3;
    padding: 0rem 2rem;
    order: ${({ reverse }) => (reverse ? '2' : '1')};

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p {
        padding-bottom: 1rem;
        font-size: 1.3rem;
    }

    @media screen and (max-width: 768px) {
        
    }
`;

const Arrow = styled(IoMdArrowBack)`
    margin-left: 3px;
    transform: scale(1.08);
`;

const Image = styled.div`
    order: ${({ reverse }) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    @media screen and (max-width: 768px) {
        order: ${({ reverse }) => (reverse ? '2' : '1')};
        height: 50vh;
    }

    img {
        
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 767px) {
            
        }
    }
`;


const DetailsSection = ({ referenceData }) => {

    const { id } = useParams();

    const reference = referenceData.find(x => x.id === id);

    return (
        <Section>
            <Container>
                <Desc reverse={reference.reverse}>
                    <h1>{reference.heading}</h1>
                    <p>{reference.paragraphOne}</p>
                    <p>{reference.paragraphTwo}</p>

                    <Button primary="true" to="/references">
                    <Arrow />
                        Zurück
                    </Button>
                </Desc>
                <Image reverse={reference.reverse}>
                <img src={reference.image} alt="reference" />
                </Image>
            </Container>
        </Section>
    )
}


export default DetailsSection
