export const impressumData = [
    "Firmenname: Ingenieurbüro Michael Frank",
    "Freiberuflich Selbstständig",
    "Anschrift: Brünnlfeldstraße 6",
    "85302 Gerolsbach",
    "Deutschland/Bayern",
    "Telefon: 08250/997815",
    "Email: mf[at]ebl-sv.de",
    "Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:",
    "DE 298 495999",
    "Inhaltlich Verantwortlicher gemäß § 6 MDStV:",
    "Michael Frank",
    "",
]