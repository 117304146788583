import Image1063 from '../images/Hybrid1063.jpg'
import Uebergang from '../images/Uebergang.jpg'
import Baubetreuung from '../images/Baubetreuung.JPG'
import Fahrzeugumbau from '../images/Fahrzeugumbau.jpeg'
import Sicherheitsanalyse from '../images/Sicherheitsanalyse.JPG'

export const referenceData = [

{
    heading: 'Projekt e-Hybrid 1063',
    paragraphOne: 'Erarbeitung einer Machbarkeitsstudie sowie Bau eines temporären Prototypen für die Aurüstung einer elektrischen Verschublokomotive mit Energiespeicher.',
    paragraphTwo: 'Aufgebaut wurde ein Speicher mit 144 kWh Litium-Eisen-Phosphat Zellen und 11 kWh Superkondensatoren. Der Speicher wurde im Betrieb unter Oberleitung wieder aufgeladen. Nach sehr erfolgreichen Versuchsfahrten wurde die Lokomotive vertragsgemäß auf den Ursprungszustand zurückgebaut. ',
    image: Image1063,
    reverse: false,
    id: '1063'
},

{
    heading: 'Projekt Reaktivierung',
    paragraphOne: 'Reaktivierung einer stillgelegten Strecke für den öffentlichen Güter - und Personenverkehr.',
    paragraphTwo: 'Die Strecke wurde von der Deutschen Bundesbahn 1985 im Personenverkehr stillgelegt und danach 30 Jahre durch eine Museumsbahn genutzt. Aktuell wird ein Teilstück wieder im Güterverkehr befahren, ein weiterer Abschnitt wird in Kürze für den Personenverkehr wieder ertüchtigt.',
    image: Uebergang,
    reverse: true,
    id: 'Reaktivierung'
},

{
    heading: 'Baubetreuung',
    paragraphOne: 'Werks- und Kundenabhnahmen von Neufahrzeugen',
    paragraphTwo: null,
    image: Baubetreuung,
    reverse: false,
    id: null
},
{
    heading: 'Fahrzeugumbau',
    paragraphOne: 'Konzeption, begleitende Konstrkution und Anstoß des Zulassungsprozesses für den Umbau und die Modernisierung von Waggons und Lokomotiven.',
    paragraphTwo: null,
    image: Fahrzeugumbau,
    reverse: true,
    id: null
},
{
    heading: 'Sicherheitsanalysen',
    paragraphOne: <div>Analyse von des Betriebs von Anschlussbahnen hinsichtlich Sicherheit von<br/> - Bahnbetrieb<br/> - Fahrzeugen<br/>- Infrastruktur<br/>analog der Vorschriften von Deutschland bzw. der EU</div>,
    paragraphTwo: null,
    image: Sicherheitsanalyse,
    reverse: false,
    id: null
}]

