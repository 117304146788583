import React from 'react'
import { IoMdArrowForward } from 'react-icons/io';
import styled from 'styled-components'
import { Button } from './Button';

const Section = styled.section`
    height: 100%;
    width: 100%;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    top: 60px;

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`;

const Desc = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.3;
    padding: 0rem 2rem;
    order: ${({ reverse }) => (reverse ? '2' : '1')};

    h1 {
        margin-bottom: 1rem;
        font-size: clamp(1.5rem, 6vw, 2rem);
    }

    p {
        padding-bottom: 1rem;
        font-size: 1.3rem;
    }

    @media screen and (max-width: 768px) {
        max-height: 50vh;
        
    }
`;

const Arrow = styled(IoMdArrowForward)`
    margin-left: 3px;
    transform: scale(1.08);
`;

const Image = styled.div`
    order: ${({ reverse }) => (reverse ? '1' : '2')};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    @media screen and (max-width: 768px) {
        order: ${({ reverse }) => (reverse ? '2' : '1')};
        height: 50vh;
    }

    img {
        
        width: 90%;
        height: 90%;
        object-fit: cover;

        @media screen and (max-width: 767px) {
            
        }
    }
`;


const InfoSection = ({ references }) => {
    


    return (
        <Section>
            {references.map((reference, index) => {
            if (reference.id != null){

            return(
            <Container key={index}>
                <Desc reverse={reference.reverse}>
                    <h1>{reference.heading}</h1>
                    <p>{reference.paragraphOne}</p>
                    <Button primary="true" to={`/references/${reference.id}`}>
                        Mehr
                        <Arrow />
                    </Button>
                </Desc>
                <Image reverse={reference.reverse}>
                <img src={reference.image} alt="reference" />
                </Image>
            </Container>
            )
            }

            else{
                return(
                    <Container key={index}>
                        <Desc reverse={reference.reverse}>
                            <h1>{reference.heading}</h1>
                            <p>{reference.paragraphOne}</p>
                        </Desc>
                        <Image reverse={reference.reverse}>
                        <img src={reference.image} alt="reference" />
                        </Image>
                    </Container>
                )
            }

            })}
        </Section>
    )
}

export default InfoSection
