
import emailjs from 'emailjs-com';
import React from 'react'
import styled from 'styled-components';
import { Button } from './Button';
import bg from '../images/design.jpg';


const ContactSection = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
`;

const ContactWrapper = styled.div`
    height: 800px;
    width: 80vw;
    max-width: 100vw;
    position: absolute;
    top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    
    @media screen and (min-width: 768px) {
        flex-direction: row;
    }
`;

const Background = styled.img`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
`;

const Info = styled.div`
    background-color: #303030;
    color: #fff;
    padding: 20px;
    display: grid;
    grid-row-gap: 30px;

    font-size: larger;
    
    
`;

const Form = styled.div`
    background-color: rgba(255, 255, 255, 0.9);
    padding: 15px 15px 15px 15px;
    display: grid;
    grid-row-gap: 30px;


    h2{
        padding: 1%;
    }

    @media screen and (min-width: 768px) {
        
    }
`;


const InputWrapper = styled.div`

    input{
        width: 300px;
    }

    input, textarea{
        padding: 9px;
        border: none;
        outline: none;
        border-bottom: 1px solid #303030;
        background: none;
    }


`;

const Input = ({size="small", type, id, placeholder}) => {

   
    

    if (size === "small") {
        return (
            <InputWrapper>
                <input type={type} id={id} placeholder={placeholder}></input>
            </InputWrapper>
        )
    } else {
        return (
            <InputWrapper>
                <textarea type={type} id={id} cols="50" rows="8" placeholder={placeholder}></textarea>
            </InputWrapper>
        )
    }
}


const Contact = ({email, tel, address}) => {

    function sendEmail(e) {
        e.preventDefault();

        var templateParams = {
            name: document.getElementById('sender_name').value,
            email: document.getElementById('sender_email').value,
            subject: document.getElementById('subject').value,
            message: document.getElementById('message').value
        };

        function success() {
            document.getElementById('submit').style.backgroundColor="#00a12e";
            document.getElementById('submit').innerHTML = "Gesendet";
            
            setTimeout(() => {
                document.getElementById('submit').style.backgroundColor="#303030";
                document.getElementById('submit').innerHTML = "Senden";
                document.getElementById('form').reset();
            }, 3000)
        }

        emailjs.send('test', 'template_jus1uha', templateParams, "user_m4mAQQKg1U203uURBDEGd")
        .then(function(response) {
            console.log('SUCCESS!', response.status, response.text);
            success();
        }, function(error) {
            console.log('FAILED...', error);
        });

}


    return (
        <ContactSection>
            <Background src={bg} alt="Background"/>
            <ContactWrapper>
                <Info>
                    <h2>Kontaktinformationen</h2>
                    <span>E-Mail: {email}</span>
                    <span>Telefon: {tel}</span>
                    <span>Addresse: {address}</span>
                </Info>
                <form id="form">
                    <Form>
                        <h2>Kontakt</h2>
                        <Input size="small" type="text" id="sender_name" placeholder="Ihr Name"></Input>
                        <Input size="small" type="text" id="sender_email" placeholder="Ihre Email"></Input>
                        <Input size="small" type="text" id="subject" placeholder="Betreff"></Input>
                        <Input size="big" type="text" id="message" placeholder="Nachricht"></Input>
                        <Button primary="true" id="submit" onClick={sendEmail}>Senden</Button>
                    </Form>
                </form>
            </ContactWrapper>
        </ContactSection>
    )
}


export default Contact
