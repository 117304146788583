import React from 'react'
import styled, {css} from 'styled-components/macro'
import {Link } from 'react-router-dom'
import { menuData } from '../data/MenuData';
import {Button} from './Button';
import {FaBars} from 'react-icons/fa';
import Logoimage from '../images/Logo_labeled.png';

const Nav = styled.nav`
    height: 60px;
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
    z-index: 100;
    position: fixed;
    width: 100%;
`;


const NavLink = css`
    color: #fff;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
`;

const Logo = styled.div`
    background-color: #fff;
    height: 4rem;
    width: 10rem;
    position: relative;
    top: -1em;

    img {
        object-fit: scale-down;
        height: inherit;
        width: inherit;
    }

`;


const MenuBars = styled(FaBars)`
    display: none;

    @media screen and (max-width: 768px) {
        display: block;
        height: 40px;
        width: 40px;
        top: 0;
        right: 0;
        position: absolute;
        cursor: pointer;
        transform: translate(-80%, 25%);
    }
`;
const NavMenu = styled.span`
    display: flex;
    align-items: center;
    background-color: #303030;
    padding: 1.5rem 0rem;
    position: relative;
    height: 4rem;
    top: -1rem;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const NavMenuLinks = styled(Link)`
    ${NavLink}
`;

const NavBtn = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;

    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Navbar = ({toggle}) => {
    return (
        <Nav>
            <Logo to="/">
                <img src={Logoimage} alt="Home"></img>
            </Logo>
            <MenuBars onClick={toggle} />
            <NavMenu>
                {menuData.map((item, index) => (
                    <NavMenuLinks to={item.link} key={index}>
                        {item.title}
                    </NavMenuLinks>
                ))}
            </NavMenu>
            <NavBtn>
                <Button to="/contact" primary='true'>
                    Kontakt
                </Button>
            </NavBtn>
        </Nav>
    )
}

export default Navbar;
