import React, {useState} from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero.js';
import { SliderData } from './data/HeroData.js';
import { referenceData } from './data/referenceData.js';
import Dropdown from './components/Dropdown';
import InfoSection from './components/InfoSection.js';
import GlobalStyle from './globalStyles'
import { Route, Switch } from 'react-router';
import Contact from './components/Contact';
import {ContactData} from './data/ContactData.js';
import DetailsSection from './components/DetailsSection';
import Aboutme from './components/aboutme';
import { kurzprofil } from './data/aboutmeData';
import Impressum from './components/impressum';
import { impressumData } from './data/impressumData';

function App() {
  const [isOpen, setIsOpen] = useState(false)
  
  const toggle = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <GlobalStyle />
      <Navbar toggle={toggle}/>
      <Dropdown isOpen={isOpen} toggle={toggle} />
      
      <Switch>
        <Route exact path="/">
          <Hero slides={SliderData}/>
        </Route>
        <Route exact path="/references">
          <InfoSection references={referenceData}/>
        </Route>
        <Route path="/references/:id">
          <DetailsSection referenceData={referenceData}/>
        </Route>
        <Route path="/aboutme">
          <Aboutme profile={kurzprofil}/>
        </Route>
        <Route path="/contact">
          <Contact {...ContactData}></Contact>
        </Route>
        <Route path="/impressum">
          <Impressum impressumData={impressumData}/>
        </Route>
      </Switch>
    </>
  );
}

export default App;
