import Hybrid1063 from '../images/Hybrid1063.jpg';
import Radreifenstapel from '../images/Radreifenstapel.jpg';
import SETG from '../images/setg.jpg';
import Vectron from '../images/vectron.jpg'
//import Image from '../images/'
//import Image from '../images/'
//import Image from '../images/'

export const SliderData = [
    {
        title: "Reaktivierung",
        desc: "Wiederinbetriebnahme Nördlingen-Dinkelsbühl",
        path: '/references',
        label: 'Mehr',
        image: SETG,
        alt: 'Setg'
    },
    {
        title: "Eisenbahnbetriebsleiter",
        desc: "",
        path: '/references',
        label: 'Mehr',
        image: Hybrid1063,
        alt: 'EBL'
    },
    {
        title: "Sachverständiger",
        desc: "",
        path: '/references',
        label: 'Mehr',
        image: Radreifenstapel,
        alt: 'Radreifenstapel'
    },
    {
        title: "Vectron",
        desc: "",
        path: '/aboutme',
        label: 'Mehr',
        image: Vectron,
        alt: 'Vectron'
    },
]