import React from 'react'
import styled from 'styled-components'
import FrankHeadshot from '../images/FrankM.jpg'

const Section = styled.div`
    min-height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

`

const Wrapper = styled.div`
    width: 50vw;

    @media (max-width: 768px) {
        width: 80vw;
    }
`

const Photo = styled.img`
    width: 200px;
    position: absolute;
    top: 100px;
    left: 50px;
    padding: 15px;

    @media (max-width: 768px) {
        left: calc(50vw - 100px);
    }
`

const Text = styled.div`
    width: inherit;
    position: absolute;
    top: 100px;
    padding-bottom: 50px;
    letter-spacing: 1.1px;
    overflow-wrap: break-word;

    h2 {
        padding: 1.5rem 0;
    }

    ul {
        padding-left: 30px;
    }

    ul li {
        padding: 0.1rem 0;
    }

    @media (max-width: 768px) {
        top: 400px;
    }
`

const ProfileContainer = styled.div`

`

const Aboutme = ({profile}) => {
    return (
        <Section>
            <Wrapper>
                <Photo src={FrankHeadshot}/>
                <Text >
                    {profile.map((section, index) => {
                        return(
                            <ProfileContainer key={index}>
                                <h2>{section.title}</h2>
                                <ul>
                                {section.content.map(li => {
                                    console.log(li)
                                    console.log(Array.isArray(li))
                                    if (Array.isArray(li)) {
                                        return(
                                            <ul>
                                            {li.map(li2 => {
                                                return(<li>{li2}</li>)
                                            })}
                                            </ul>
                                        )
                                    } else {
                                    return(
                                        <li>{li}</li>
                                    )}
                                })}
                                </ul>
                            </ProfileContainer>
                        )
                    })}               
                </Text>
            </Wrapper>
        </Section>
    )
}

export default Aboutme
