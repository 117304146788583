export const kurzprofil = [
    {
        title: "Qualifikationen",
        content: ["Diplom-Wirtschaftsingenieur (Fachhochschule Schweinfurt) Studienschwerpunkt Materialwirtschaft und Logistik",
        "Diplomarbeit: Instandhaltung von Schienenfahrzeugen",
        "Eisenbahnbetriebsleiter nach Eisenbahnbetriebsleiterprüfungsverordnung 2014",
        "Anerkennung zum Sachverständigen für Schienenfahrzeugtechnik bei der Landeseisenbahnaufsicht (Regierung von Oberbayern) gemäß EBOA Bayern",
        "Fachexperte für ECM-Auditierung nach DVO 2019/779",
        "Triebfahrzeugführer für Schienenfahrzeuge Klasse III"
    ]
        
    },
    {
        title: "Berufstätigkeit",
        content: ["Krauss Maffei Verkehrstechnik GmbH/Siemens AG", ["10 Jahre Erfahrung in der Herstellung von Lokomotiven, darunter Produktfamile \"Vectron\" während der Konstruktionsphase und Bau von 12 Prototypen"],
            "4 Jahre in verantwortlicher Position bei Unternehmen im Bahnsektor", ["Leiter Technik internationales EVU im Güterverkehr"], ["Betriebsleiter nationales EVU mit Schwerpunkt Gleisbaulogistik, 25 MA"], ["Geschäftsführer mit Aufbau EVU mit internationalem Güterverkehr, grenzüberschreitender Baulogistik und Baubetriebsplanung, 23 MA"],
            "seit Januar 2014 freiberuflicher Berater im schienengebundenen Verkehr", ["Hauptuntersuchung Schienenfahrzeuge und Prüfung von Triebfahrzeugführern nach EBOA (Bayern)"], ["Unfalluntersuchung, EVU-Notdienst, Krisenmanagement"], ["Optimierung von Gleisanlagen von Industriebetrieben und Umschlagsanlagen in deren Strukturen und Abläufen"], ["Innovationsmanagement im Schienenfahrzeugwesen"], ["Fachunterrichte in Bahnbetrieb und Fahrzeugtechnik"], ["Eisenbahnbetriebsleiter bei mehreren EVU / EIU / Anschlussbahnen"], ["Geschäftsführer Mittelfränkische Eisenbahnbetriebs GmbH, Reaktivierung der Strecke Nördlingen – Dombühl mit Abschnitt für SPNV"],
            ]
    },
    {
        title: "Publikationen",
        content: ["Verschiedene Vorträge auf Tagungen und Fachkongressen", "Mitarbeit am Kommentar zur EBO"]
    },
    {
        title: "Sprachkenntnisse",
        content: ["Deutsch (Muttersprache)",
        "Englisch (in Wort und Schrift)",
        "Französisch (in Wort und Schrift)",
        "Neugriechisch (Grundkenntnisse)"
    ]
    }
    
]